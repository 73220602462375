@import '../../styles.module.scss';

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .map {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    mask: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $colorBorder;
    border-radius: 0.25rem;
    padding: 2rem;
    background-color: white;
    z-index: 20;

    img {
      width: 16rem;
    }

    h1 {
      font-size: 1rem;
      max-width: 20rem;
      margin: 0;
      font-weight: 500;
      text-align: center;
    }

    .btnSignIn {
      align-self: flex-end;
    }

    > * {
      margin-top: 1rem;
    }

    > :first-child {
      margin-top: 0rem;
    }
  }
}
