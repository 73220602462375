@import '../../styles.module.scss';

$pd: 1rem;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: calc(#{$pd} / 2);
  background-color: $colorBg;
  padding: $pd;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
}

.page {
  display: flex;
  flex-direction: column;
  background-color: $colorBgDark;
  padding: $pd;
  height: calc(100vh - 2 * #{$pd});

  h1 {
    margin-top: calc(-1 * #{$pd});
    margin-left: calc(-1 * #{$pd});
    font-size: 3rem;
    width: calc(100vw - #{$pd});
    padding-top: 0rem;
    padding-bottom: 3rem;
    padding-left: $pd;
    background-color: $colorDefault;
    color: $colorBg;

    span {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 300;
    }
  }

  h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0 $pd;
    font-size: 1rem;
    background-color: $colorDefault;
    color: $colorBg;
    border-radius: 0 0 $pd $pd;
    opacity: 0.9;
    z-index: 3;
  }

  .upper {
    display: flex;
    flex-direction: column;
    margin-top: -5rem;

    .latestObs {
      display: flex;
      max-width: 80rem;

      .obs {
        @extend .card;
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding-top: 3rem;

        .icon {
          font-size: 2rem;
          margin-right: 1rem;
          opacity: 0.8;
        }

        .title {
          font-size: 1rem;
          opacity: 0.8;
        }

        .value {
          margin-top: -0.5rem;
          font-size: 3rem;
          font-weight: bold;

          .unit {
            margin-left: 0.5rem;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .below {
    display: flex;
    flex: 1;

    .charts {
      flex: 2;
      display: flex;
      flex-wrap: wrap;

      .chart {
        @extend .card;
        width: calc(50% - #{$pd} * 3);

        .content {
          margin-top: 2rem;
        }
      }
    }

    .graphics {
      flex: 1;
      display: flex;
      flex-direction: column;

      .spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      .image {
        @extend .card;
        padding: unset;
        position: relative;
        min-height: 30vh;

        img {
          position: relative;
          display: block;
          border-radius: 0.5rem;
          overflow: hidden;
          max-height: 50vh;
          object-fit: cover;
          z-index: 2;
        }
      }

      .map {
        @extend .card;
        padding: unset;
        flex: 1;

        h2 {
          z-index: 999;
        }

        .icon {
          font-size: 2rem;
        }
      }
    }
  }
}
